<template>
    <div class="change-data-page">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="my-5 p-3">

                        <h1>Zmiana danych</h1>
                        <div class="mb-3 text-right">
                        <router-link :to="{name: 'CustomerAccount'}" >Powrót</router-link>
                        </div>                        
                        <div v-if="isChanged">
                            {{ successMessage }}
                        </div>
                        <div v-else class="form-container">
                            <form method="post" @submit.prevent="beforeSubmit" action="">
                                <inputText v-model="form.name" name="name" label="Imię" :error="validation.firstError('form.name')" />
                                <inputText v-model="form.surname" name="surname" label="Nazwisko"  :error="validation.firstError('form.surname')"  />
                                
                                <inputEmail v-model="form.email" name="email" label="E-mail" :showSpinner="validation.isValidating('form.email')" 
                                    :error="validation.firstError('form.email')" />
                                
                                <dismissAlert ref="errorAlert" />

                                <div class="text-center">
                                    <buttonLoader type="submit" class="btnLong" :isLoading="inProgress" label="Zmień" />
                                </div>
                            </form>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import SimpleVueValidation from 'simple-vue-validator';
const Validator = SimpleVueValidation.Validator;

export default {
    name: "adminChangeData",
    data: () => ({
        inProgress: false,
        filter: [],
        isChanged: false,
        successMessage: '',
        form: {
            name: null,
            surname: null,
            email: null,
            phone: null
        }
    }),
    mounted(){
        const me = this.$store.getters['auth/me'];
        this.form.name = me.name,
        this.form.surname = me.surname,
        this.form.email = me.email
    },
    validators: {
        'form.name': function (value) {
            return Validator.value(value).required().maxLength(100);
        },
        'form.surname': function (value) {
            return Validator.value(value).required().maxLength(100);
        },
        'form.phone': function (value) {
            if (value)
                return Validator.value(value).maxLength(50);
        },
        'form.email': {
            cache: 'last',
            debounce: 200,
            validator: function (value) {
                let vMessage = Validator.value(value)
                    .required()
                    .minLength(5)
                    .maxLength(255)
                    .email();
                if ( vMessage._messages.length == 0 ) {
                    vMessage = Validator.custom(function () {  
                        if (!Validator.isEmpty(value)) {
                            return this.$store.dispatch('auth/checkEmailIsFree', { email: this.form.email, id: null } )
                                .catch( ( error ) =>  {
                                    return error;
                                });
                        }
                    }.bind(this) );
                }
                return vMessage;
            }
        },
    },
    methods: {
        beforeSubmit(){
            this.inProgress = true;
            this.$validate().then( this.submit );
        },
        submit(success){
            if (success) {
                this.$store.dispatch('admin/changeData', this.form)
                    .then( message => {
                        this.isChanged =  true;
                        this.successMessage = message;
                        this.$store.dispatch('auth/loadMe');
                    }).catch( () =>  {
                        this.inProgress = false;
                        this.$refs.errorAlert.alertShowDanger('Błąd zmiany danych');
                    });
            } else {
                this.inProgress = false;
            }
        }
    }
};
</script>